<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
        <el-form-item label="标题">
          <el-input
            placeholder="请输入标题"
            v-model="queryForm.title"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            type="datetimerange"
            v-model="times"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item class="generate">
          <el-button type="primary" @click="toAdd"
            >添加精品公开课</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="openClassList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>
       
        <el-table-column label="标题" prop="title" align="center"></el-table-column>
        <el-table-column label="描述" prop="desc" align="center"></el-table-column>
        <el-table-column label="缩略图" align="center">
          <template slot-scope="scope">
            <div class="thumb_box">
                <img :src="scope.row.thumb_4_3_url" alt="">
            </div>
           
          </template>
        </el-table-column>

        <el-table-column
            label="是否显示"
            prop="display"
            align="center"
          >
          <template slot-scope="scope">
              <el-tag
                type="success"
                v-if="scope.row.display == 1"
              >
                显示
              </el-tag>
              <el-tag type="danger" v-else
                >隐藏
              </el-tag>
            </template>
        </el-table-column>
        <el-table-column
            label="课程老师"
            align="center"
          >
          <template slot-scope="scope">
              <div class="user_box">
                <div class="user_img">
                  <img :src="scope.row.goodsTeacher.avatar" alt="" srcset="">
                </div>
                <div class="user_name">
                  {{scope.row.goodsTeacher.name}}
                </div>
              </div>
          </template>
        </el-table-column>
        <el-table-column label="课程时间" prop="class_time" align="center"></el-table-column>
        <el-table-column label="时间" align="center">
          <template slot-scope="scope">
            {{
                $moment(scope.row.create_time * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
          </template>
        </el-table-column>
       
        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="editGoods(scope.row)"
              size="small"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="removeOpenClass(scope.row.id)"
              size="small"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>

      <!-- 添加精品公开课 -->
    <el-dialog :title="addtitle" :visible.sync="addDialog" :center=true width="40%" @close="closed" :close-on-click-modal=false>
      <el-form :model="addForm"  ref="addForm">
        <el-form-item el-form-item label="标题" label-width="100px">
            <el-input
              type="text"
              v-model="addForm.title"
              placeholder="请输入标题"
            />
        </el-form-item>
        <el-form-item el-form-item label="描述" label-width="100px">
            <el-input
              type="textarea"
              :rows="6"
              placeholder="请输入描述"
              v-model="addForm.desc"
            />
        </el-form-item>

        <el-form-item el-form-item label="16:9缩略图" label-width="100px">
            <el-row>
              <el-upload
                class="avatar-uploader"
                action="123"
                :show-file-list="false"
                accept="image/*"
                :before-upload="beforeThumbUpload16_9"
                style="width: 300px;height:180px"
              >
                  <img
                    v-if="thumbSrc16_9"
                    style="width: 100%;height:100%"
                    :src="thumbSrc16_9"
                    class="avatar"
                  />
                  <i
                  v-else
                  class="el-icon-plus avatar-uploader-icon"
                  ></i>
              </el-upload>
            </el-row>
            <el-row>
              <div class="tips">建议上传300*180尺寸</div>
            </el-row>
        </el-form-item>

        <el-form-item el-form-item label="4:3缩略图" label-width="100px">
            <el-row>
              <el-upload
                class="avatar-uploader"
                action="123"
                :show-file-list="false"
                accept="image/*"
                :before-upload="beforeThumbUpload4_3"
                style="width: 300px;height:200px"
              >
                  <img
                    v-if="thumbSrc4_3"
                    style="width: 100%;height:100%"
                    :src="thumbSrc4_3"
                    class="avatar"
                  />
                  <i
                  v-else
                  class="el-icon-plus avatar-uploader-icon"
                  ></i>
              </el-upload>
            </el-row>
            <el-row>
              <div class="tips">建议上传300*200尺寸</div>
            </el-row>
        </el-form-item>

        <el-form-item el-form-item label="视频" label-width="100px">
          <div v-if="addForm.video_url" class="video_box">
            <video
              :src="addForm.video_url"
              style="cursor: pointer;outline: none;"
              controls="controls" 
              ref="videoPlay"
            ></video>
          </div>
          <el-button type="primary" @click="selectvideo"
            >{{isAdd=='add'?'上传视频':'修改视频'}}</el-button
          ><span class="file-name">{{ fileName }}</span>
          <el-progress :percentage="progress" v-if="progress"></el-progress>
          <input
            type="file"
            hidden
            ref="video"
            @change="selectVideoFiles"
            accept="video/*"
          />
        </el-form-item>

        <el-form-item el-form-item label="是否展示:" label-width="100px">
          <el-select
            v-model="addForm.display"
            placeholder="请选择是否展示"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in displayArr"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item el-form-item label="老师:" label-width="100px">
          <el-select
            v-model="addForm.teacher_id"
            placeholder="请选择老师"
            clearable
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in selectTeacher"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item el-form-item label="课程时间" label-width="100px">
            <el-time-picker
              v-model="addForm.class_time"
              value-format = 'HH:mm:ss'
              format = 'HH:mm:ss'
              placeholder="请选择课程时间">
            </el-time-picker>
        </el-form-item>


        <el-form-item el-form-item label="内容：" label-width="100px">
            <quill-editor
              v-model="addForm.content"
              ref="myQuillEditor"
            >
            </quill-editor>
        </el-form-item>
       
        <el-form-item label-width=40% style="margin-top: 20px;">
           <el-button type="primary" v-if="isAdd=='add'" @click="addNow" >立即添加</el-button>
           <el-button type="primary" v-else @click="editReadNow">立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    </el-card>
  </div>
</template>

<script>
//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
var COS = require("cos-js-sdk-v5");

import {
  OpenClassList,
  OpenClassAdd,
  OpenClassEdit,
  OpenClassDel,
  getOpenClassCosToken,
} from "@/api/Korean/korean_open_class.js";
import { getTeacherList } from "@/api/select";
export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      queryForm: {
        title: "",
        page: 1,
        limit: 10,
        start_time: null,
        end_time: null,
      },
      openClassList: [],
      total: 0,
      addDialog: false,
      times: null,
      addForm: {
        title: "",
        desc: "",
        thumb_16_9: "",
        thumb_4_3: "",
        display: "",
        content: "",
        video: "",
        teacher_id: "",
        class_time: "",
      },
      displayArr: [
        { value: "显示", key: 1 },
        { value: "隐藏", key: 0 },
      ],
      addtitle: "",
      isAdd: "add",
      thumbSrc16_9: "",
      thumbSrc4_3: "",

      fileName: "",
      progress: 0,
      selectTeacher: [],
    };
  },

  created() {
    this.getOpenClassList();
    this.getTeacherListData();
  },
  methods: {
    // 获取老师列表
    getTeacherListData() {
      getTeacherList({ mold: "korean" }).then((res) => {
        this.selectTeacher = res.data;
      });
    },
    //   韩语APP精品公开课列表
    getOpenClassList() {
      OpenClassList(this.queryForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.total = res.data.total;
        this.openClassList = res.data.data;
      });
    },

    // 删除精品公开课
    async removeOpenClass(id) {
      const result = await this.$confirm(
        "是否要删除该精品公开课?",
        "删除提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      OpenClassDel({ id: id }).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除精品公开课成功");
        this.getOpenClassList();
      });
    },

    // 添加弹窗
    toAdd() {
      this.isAdd = "add";
      this.addtitle = "添加精品公开课";
      this.addDialog = true;
    },

    // 上传缩略图16:9
    beforeThumbUpload16_9(file) {
      const isSize = file.size / 1024 / 1024 < 10;
      if (!isSize) {
        this.$message.error("上传缩略图不能超过 10MB!");
        return;
      }
      let that = this;
      getOpenClassCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            Body: file, // 上传文件对象
            onProgress: function (progressData) {},
          },
          function (err, data) {
            if (data) {
              that.addForm.thumb_16_9 = res.data.key;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                that.thumbSrc16_9 = e.target["result"]; // 获取文件装换后的Base64位文件
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },

    // 上传缩略图4:3
    beforeThumbUpload4_3(file) {
      const isSize = file.size / 1024 / 1024 < 10;
      if (!isSize) {
        this.$message.error("上传缩略图不能超过 10MB!");
        return;
      }
      let that = this;
      getOpenClassCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            Body: file, // 上传文件对象
            onProgress: function (progressData) {},
          },
          function (err, data) {
            if (data) {
              that.addForm.thumb_4_3 = res.data.key;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                that.thumbSrc4_3 = e.target["result"]; // 获取文件装换后的Base64位文件
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },

    // 点击选择视频文件按钮
    selectvideo() {
      this.$refs.video.click();
    },

    // 选中视频
    selectVideoFiles(e) {
      let file = this.$refs.video.files[0];
      this.fileName = file.name;
      const isSize = file.size / 1024 / 1024 < 500;
      if (!isSize) {
        this.$message.error("上传视频不能超过 500MB!");
        return;
      }

      let that = this;
      getOpenClassCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              that.progress = progressData.percent * 100;
            },
          },
          function (err, data) {
            if (data) {
              that.addForm.video = res.data.key;
            }
            e.target.value = "";
          }
        );
      });
    },

    // 添加精品公开课
    addNow() {
      if (this.addForm.title == "") {
        this.$message.error("请输入课程标题");
        return;
      } else if (this.addForm.desc == "") {
        this.$message.error("请输入课程描述");
        return;
      } else if (this.addForm.thumb_16_9 == "") {
        this.$message.error("请上传16比9尺寸缩略图");
        return;
      } else if (this.addForm.thumb_4_3 == "") {
        this.$message.error("请上传4比3尺寸缩略图");
        return;
      } else if (this.addForm.display === "" || this.addForm.display === null) {
        this.$message.error("请选择是否展示");
        return;
      } else if (
        this.addForm.teacher_id === "" ||
        this.addForm.teacher_id === null
      ) {
        this.$message.error("请选择老师");
        return;
      } else if (
        this.addForm.class_time === "" ||
        this.addForm.class_time === null
      ) {
        this.$message.error("请选择课程时间");
        return;
      }
      OpenClassAdd(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加精品公开课成功");
        this.addDialog = false;
        this.getOpenClassList();
      });
    },

    // 点击编辑图标
    editGoods(item) {
      this.isAdd = "edit";
      this.addtitle = "编辑精品公开课";
      this.addDialog = true;
      this.addForm = JSON.parse(JSON.stringify(item));
      this.thumbSrc4_3 = this.addForm.thumb_4_3_url;
      this.thumbSrc16_9 = this.addForm.thumb_16_9_url;
    },

    // 点击立即修改
    editReadNow() {
      if (this.addForm.title == "") {
        this.$message.error("请输入课程标题");
        return;
      } else if (this.addForm.desc == "") {
        this.$message.error("请输入课程描述");
        return;
      } else if (this.addForm.thumb_16_9 == "") {
        this.$message.error("请上传16比9尺寸缩略图");
        return;
      } else if (this.addForm.thumb_4_3 == "") {
        this.$message.error("请上传4比3尺寸缩略图");
        return;
      } else if (this.addForm.display === "" || this.addForm.display === null) {
        this.$message.error("请选择是否展示");
        return;
      } else if (
        this.addForm.teacher_id === "" ||
        this.addForm.teacher_id === null
      ) {
        this.$message.error("请选择老师");
        return;
      } else if (
        this.addForm.class_time === "" ||
        this.addForm.class_time === null
      ) {
        this.$message.error("请选择课程时间");
        return;
      }
      OpenClassEdit(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改精品公开课成功");
        this.addDialog = false;
        this.getOpenClassList();
      });
    },

    // 弹窗关闭事件
    closed() {
      this.addForm = {
        title: "",
        desc: "",
        thumb_16_9: "",
        thumb_4_3: "",
        display: "",
        content: "",
        video: "",
        teacher_id: "",
        class_time: "",
      };
      this.thumbSrc16_9 = "";
      this.thumbSrc4_3 = "";
      this.progress = 0;
      this.fileName = "";
    },

    //  查询
    toQuery() {
      this.queryForm.page = 1;
      if (this.times) {
        this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
      } else if (this.times == null) {
        this.queryForm.start_time = null;
        this.queryForm.end_time = null;
      }
      this.getOpenClassList();
    },
    handleSizeChange(size) {
      this.queryForm.limit = size;
      this.getOpenClassList();
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getOpenClassList();
    },
  },
};
</script>

<style scoped lang="less">
.generate {
  float: right;
}
// .el-tag {
//   cursor: pointer;
// }
::v-deep .ql-editor {
  min-height: 400px;
}
.thumb_box {
  width: 100%;
  height: 100%;
  img {
    width: 150px;
    height: 90px;
  }
}

.tips {
  color: red;
}
::v-deep .el-upload--text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video_box {
  width: 100%;
  height: 300px;
  margin-bottom: 10px;
  video {
    width: 100%;
    height: 100%;
  }
}

.user_box {
  display: flex;
  align-items: center;
  justify-content: center;
  .user_img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .user_name {
    margin-left: 5px;
  }
}
</style>
