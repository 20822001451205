import request from "@/utils/request";

// 韩语APP公开课列表
export function OpenClassList(params) {
    return request({
        url: '/admin/korean_v3/openClassList',
        method: 'get',
        params
    })
}

// 添加韩语APP公开课
export function OpenClassAdd(data) {
    return request({
        url: '/admin/korean_v3/openClassAdd',
        method: 'post',
        data
    })
}

// 编辑韩语APP公开课
export function OpenClassEdit(data) {
    return request({
        url: '/admin/korean_v3/openClassEdit',
        method: 'put',
        data
    })
}

// 删除韩语APP公开课
export function OpenClassDel(data) {
    return request({
        url: '/admin/korean_v3/openClassDel',
        method: 'delete',
        data
    })
}

// 公开课上传COS签名
export function getOpenClassCosToken(params) {
    return request({
        url: '/admin/korean_v3/getOpenClassCosToken',
        method: 'get',
        params
    })
}